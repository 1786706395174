import { DisplayText, Text, Anchor, ButtonStyle } from "@proofxyz/moonlight";
import Link from "next/link";

const NotFound: React.FunctionComponent = () => {
  return (
    <div className="flex items-center justify-center sm:justify-start h-screen max-w-screen-sm mx-auto">
      <div className="text-center sm:text-start">
        <h1 className={DisplayText.lg}>Page not found.</h1>

        <Text size="xl" color="secondary" className="mt-4 mb-8">
          We can&apos;t seem to find that page (404 error).
        </Text>
        <Link href="/" passHref>
          <Anchor
            style={ButtonStyle.primary}
            className="flex-1 sm:flex-initial w-[240px]"
          >
            Go to homepage
          </Anchor>
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
