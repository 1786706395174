import type { NextPage } from "next";

import Layout from "src/components/Layout";
import MetaTags from "src/components/MetaTags";
import NotFound from "src/components/errors/NotFound";
import BackgroundGlow from "src/components/home/BackgroundGlow";

const FourOhFourPage: NextPage = () => {
  return (
    <div>
      <MetaTags
        title={`Not found | PROOF`}
        description={`Page is not found on PROOF`}
      />

      <Layout>
        <BackgroundGlow />

        {/* relative is required to make the big glow go behind the content */}
        <div className="relative">
          <NotFound />
        </div>
      </Layout>
    </div>
  );
};

export default FourOhFourPage;
